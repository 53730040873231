import spinner from "!!raw-loader!./spinner.svg";
import * as React from "react";
import * as styles from "./spinner.module.scss";

export const Spinner = () => (
  <div
    className={styles.spinner}
    dangerouslySetInnerHTML={{ __html: spinner }}
  ></div>
);
