type Listener<T> = (payload: T) => unknown;
export type TypedEventEmitter<T> = {
  subscribe: (fn: Listener<T>) => () => unknown;
  unsubscribe: (fn: Listener<T>) => unknown;
  emit: (paylod: T) => unknown[];
};
export const TypedEventEmitter = {
  make: <T extends any>(): TypedEventEmitter<T> => {
    const listenerSet = new Set<Listener<T>>();
    const subscribe: TypedEventEmitter<T>["subscribe"] = (fn: Listener<T>) => {
      listenerSet.add(fn);
      return () => unsubscribe(fn);
    };

    const unsubscribe: TypedEventEmitter<T>["unsubscribe"] = (fn: Listener<T>) => {
      listenerSet.delete(fn);
      return;
    };

    const emit: TypedEventEmitter<T>["emit"] = (payload: T) =>
      Array.from(listenerSet).map((listener) => listener(payload));

    return {
      subscribe,
      unsubscribe,
      emit,
    };
  },
};
