import { graphql } from "gatsby";
import * as React from "react";
import * as t from "io-ts";
import * as TE from "fp-ts/lib/TaskEither";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import {
  encodeCommandForTransport,
  FetchGuards,
  IssuingURL,
  PatchCommand,
  RetrieveCommand,
  SHEET_NAME,
  SPREADSHEET_ID,
} from "./commands";
import { TypedEventEmitter } from "./eventEmitter";
import { Cipher } from "./cipher";
import { isLastDayOfMonth } from "date-fns";

// markup
export const INVITEE_WORKER_STATE_LOADING: unique symbol = Symbol("LOADING");
export const INVITEE_WORKER_STATE_SAVING: unique symbol = Symbol("SAVING");
export const INVITEE_WORKER_STATE_EMPTY: unique symbol = Symbol("EMPTY");
export const InviteeUpdateableDataCodec = t.type({
  attendance: t.union([t.null, t.number]),
  phoneNumber: t.union([t.null, t.string]),
});
export type InviteeUpdateableData = t.TypeOf<typeof InviteeUpdateableDataCodec>;
export const InviteeDataCodec = t.intersection([
  t.type({
    name: t.string,
  }),
  InviteeUpdateableDataCodec,
]);
export type InviteeData = t.TypeOf<typeof InviteeDataCodec>;
export type InviteeInfoWorkerData = {
  state:
    | null
    | typeof INVITEE_WORKER_STATE_LOADING
    | typeof INVITEE_WORKER_STATE_SAVING;
  data: null | E.Either<
    unknown,
    typeof INVITEE_WORKER_STATE_EMPTY | InviteeData
  >;
  lastUpdateError: null | unknown;
};
export type InviteeInfoWorker = InviteeInfoWorkerData & {
  id: Symbol;
  events: {
    change: TypedEventEmitter<void>;
  };
  clearLastUpdateError: () => unknown;
  fetch: (
    row: number
  ) => Promise<
    E.Either<unknown, typeof INVITEE_WORKER_STATE_EMPTY | InviteeData>
  >;
  update: (
    row: number,
    data: InviteeUpdateableData
  ) => Promise<
    E.Either<unknown, typeof INVITEE_WORKER_STATE_EMPTY | InviteeData>
  >;
  getInvitee: () => InviteeData | null;
};

export const InviteeInfoWorker = {
  make: (cipher: Cipher): InviteeInfoWorker => {
    const events = {
      change: TypedEventEmitter.make<void>(),
    };

    const fetchData: InviteeInfoWorker["fetch"] = async (row: number) => {
      if (actor.state !== null) return E.left(null);
      actor.state = INVITEE_WORKER_STATE_LOADING;
      events.change.emit();

      const retVal = await (async () => {
        const message = encodeCommandForTransport(cipher, {
          t: "retrieve",
          p: {
            sid: SPREADSHEET_ID,
            r: { r: row, s: SHEET_NAME },
          } as RetrieveCommand,
        });

        if (E.isLeft(message)) return message;

        const fetchRes = await FetchGuards.network(
          fetch(IssuingURL, {
            headers: {
              "Content-type": "application/json",
            },
            method: "POST",
            body: message.right,
          })
        );
        if (!E.isRight(fetchRes)) return fetchRes;

        const extractRes = await FetchGuards.extract(fetchRes.right);

        if (!E.isRight(extractRes)) return extractRes;

        const fetchData = extractRes.right;

        if (!t.array(t.unknown).is(fetchData))
          return E.left("Data is not array");

        const inviteeDataRes: E.Either<string, InviteeData> = pipe(
          fetchData,
          (x) => {
            const [_, nameRaw, attendanceRaw, phoneNumberRaw] = x;
            const errors: string[] = [];
            const name = pipe(String(nameRaw), (nameRaw) => {
              const name = nameRaw || "";
              if (!name) errors.push("name empty");
              return name;
            });

            const attendance = pipe(attendanceRaw, (attendanceRaw) => {
              return Number(attendanceRaw) || null;
            });

            const phoneNumber =
              (phoneNumberRaw && String(phoneNumberRaw)) || null;

            if (errors.length > 0) {
              return E.left(errors.join(", "));
            }

            return E.right({
              name,
              attendance,
              phoneNumber,
            } as InviteeData);
          }
        );
        return inviteeDataRes;
      })();

      actor.data = retVal;
      actor.state = null;
      events.change.emit();
      return retVal;
    };

    const updateData: InviteeInfoWorker["update"] = async (
      row: number,
      patchData: InviteeUpdateableData
    ) => {
      if (actor.state !== null) return E.left(null);
      actor.state = INVITEE_WORKER_STATE_SAVING;
      events.change.emit();

      const retVal = await (async () => {
        const message = encodeCommandForTransport(cipher, {
          t: "patch",
          p: {
            sid: SPREADSHEET_ID,
            pl: { c: patchData.attendance, d: patchData.phoneNumber },
            r: { r: row, s: SHEET_NAME },
          } as PatchCommand,
        });

        if (E.isLeft(message)) return message;

        const fetchRes = await pipe(
          () =>
            FetchGuards.network(
              fetch(IssuingURL, {
                headers: {
                  "Content-type": "application/json",
                },
                method: "POST",
                body: message.right,
              })
            ),
          TE.chain((x) => () => FetchGuards.extract(x))
        )();

        if (!E.isRight(fetchRes)) return fetchRes;

        return E.right(null);
      })();

      actor.state = null;
      if (E.isLeft(retVal)) {
        actor.lastUpdateError = retVal.left;
      }
      events.change.emit();

      if (E.isLeft(retVal)) {
        return retVal;
      } else {
        const fetchRetVal = await fetchData(row);
        return fetchRetVal;
      }
    };

    const getInvitee = () =>
      pipe(
        actor.data,
        (data) =>
          (data &&
            E.isRight(data) &&
            data.right !== INVITEE_WORKER_STATE_EMPTY &&
            data.right) ||
          null
      );

    const clearLastUpdateError = () => {
      actor.lastUpdateError = null;
      events.change.emit();
    };

    const actor: InviteeInfoWorker = {
      id: Symbol(),
      state: null,
      data: null,
      fetch: fetchData,
      getInvitee,
      events,
      update: updateData,
      lastUpdateError: null,
      clearLastUpdateError,
    };

    return actor;
  },
};

export const useInviteeInfoWorker = (cipher: Cipher) => {
  const [state, setState] = React.useState<null | InviteeInfoWorker>(null);
  React.useEffect(() => {
    const worker = InviteeInfoWorker.make(cipher);
    setState({ ...worker });
    worker.events.change.subscribe(() => {
      setState({
        ...worker,
      });
    });
  }, []);
  return state;
};
