import { graphql, Link } from "gatsby";
import * as React from "react";
import * as t from "io-ts";
import * as TE from "fp-ts/lib/TaskEither";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import classnames from "classnames";
import {
  Background,
  Checkbox,
  FormFrame,
  FormFrameHead,
  FormGroup,
  Layout,
  LinkButtonContainer,
  OtherStyles,
  Page,
  PageStyles,
  Subsection,
} from "../components/layout";
import * as styles from "./index.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

import intervalToDuration from "date-fns/intervalToDuration";
import { Typography } from "../components/typography";
import { Cipher } from "../helpers/cipher";
import {
  InviteeData,
  INVITEE_WORKER_STATE_LOADING,
  INVITEE_WORKER_STATE_SAVING,
  useInviteeInfoWorker,
} from "../helpers/inviteeInfoWorker";
import { Spinner } from "../components/spinner";
import { useLocation } from "@reach/router";

const cipher = Cipher.make({ rot: 3 });
const WEDDING_TIMESTAMP_NUM = 1657940400000;
const WEDDING_TIMESTAMP = new Date(WEDDING_TIMESTAMP_NUM);
const getRemainingDuration = (): Duration => {
  const start = new Date(Math.min(new Date().getTime(), WEDDING_TIMESTAMP_NUM));
  const end = WEDDING_TIMESTAMP;
  return intervalToDuration({
    start,
    end,
  });
};

export const query = graphql`
  query MyQuery {
    allFile(filter: { relativePath: { glob: "images/**" } }) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
  }
`;

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

// Any type because of reasons - look at the definition from graphql
export const createImageDataMap = (data: any) => {
  const map = new Map<string, unknown>();
  data.allFile.edges.forEach((item: any) => {
    map.set(item.node.relativePath, item.node.childImageSharp.gatsbyImageData);
  });
  return map;
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const ImageByMapContext = React.createContext<Map<string, any>>(
  new Map()
);

export const InviteeInfoContext = React.createContext<{
  inviteeInfoWorker: ReturnType<typeof useInviteeInfoWorker>;
  row: number | null;
}>({
  inviteeInfoWorker: null,
  row: 0,
});

const IndexPage = ({ data }: { data: any }) => {
  React.useEffect(() => {
    (window as any).almon = {
      ciph: cipher.s2sEncode,
    };
  }, []);

  const [row, setRow] = React.useState<null | number>(null);

  const imageMapByPath = React.useMemo(() => createImageDataMap(data), [data]);
  const inviteeInfoWorker = useInviteeInfoWorker(cipher);

  React.useEffect(() => {
    if (!inviteeInfoWorker) return;
    const inviteeRow = pipe(
      new URL(window.location.toString()),
      (url) => (url && url.searchParams.get("invitee")) || null,
      (invitee) => (invitee && cipher.s2sDecode(invitee)) || null,
      (decoded) =>
        (decoded && E.isRight(decoded) && Number(decoded.right)) || null,
      (maybeNumber) => (!Number.isNaN(maybeNumber) && maybeNumber) || null
    );

    if (!inviteeRow) return;

    setRow(inviteeRow);

    inviteeInfoWorker.fetch(inviteeRow);
  }, [inviteeInfoWorker?.id]);

  return (
    <ImageByMapContext.Provider value={imageMapByPath}>
      <InviteeInfoContext.Provider value={{ inviteeInfoWorker, row }}>
        <InvitationPage />
      </InviteeInfoContext.Provider>
    </ImageByMapContext.Provider>
  );
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

const InvitationPage = () => {
  const { inviteeInfoWorker, row } = React.useContext(InviteeInfoContext);

  return (
    <main className={styles.main}>
      <Background />
      <Layout>
        <CoverPage />
        <OurStoryPage />
        <HolyMatrimonyPage />
        {inviteeInfoWorker?.state === INVITEE_WORKER_STATE_LOADING && (
          <ReceptionAreaLoading />
        )}
        {pipe(
          inviteeInfoWorker?.getInvitee(),
          (invitee) => invitee && <ReceptionPage />
        )}
        {!row && <GiftLinkPage />}
      </Layout>
    </main>
  );
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const CoverPage = () => {
  const imageMapByPath = React.useContext(ImageByMapContext);
  return (
    <Page
      className={classnames(PageStyles.solid, PageStyles.padded)}
      contentClassName={styles.monicAndAlanSection}
    >
      <div className={classnames(styles.firstComesLove, Typography.fontPrata)}>
        First comes love, <wbr />
        then comes marriage
      </div>
      <GatsbyImage
        className={styles.danceImg}
        alt=""
        image={imageMapByPath.get("images/dance.jpg")}
      />
      <div className={classnames(styles.monicAndAlan, Typography.fontBelleza)}>
        <div className={styles.monicAndAlanInner}>
          <div className={styles.text}>
            <div>Monic</div>
            <div>
              <span className={styles.and}>& </span>Alan
            </div>
          </div>
          <div className={styles.underline} />
        </div>
      </div>
    </Page>
  );
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const OurStoryPage = () => {
  const imageMapByPath = React.useContext(ImageByMapContext);
  return (
    <Page
      className={classnames(PageStyles.solid, PageStyles.padded)}
      contentClassName={classnames(styles.ourStorySection)}
    >
      <h2 className={classnames(Typography.fontPrata, styles.heading)}>
        Our Story
      </h2>
      <p className={classnames(Typography.fontOpensans)}>
        The first interaction of the couple starts with a group order of a cup
        of bubble tea, followed with an overtime duties that needs to be
        finished. The day was long, Monic still have a lot of tasks that she
        needs to finish, but the night was longer still, so Alan took her out
        for a dinner.
      </p>
      <GatsbyImage
        className={styles.faceToFaceImg}
        alt=""
        image={imageMapByPath.get("images/face-to-face.jpg")}
      />
      <p className={classnames(Typography.fontOpensans)}>
        It was Valentine's Day, there were plenty of discounts for couples, so
        in the spirit of being frugal, the two pretended to be a couple. They
        laughed, ate, shared their theories on the PC RPG that they both love
        and they forgot the fact that they hadn't known each other for long at
        that time. In that fleeting moment, that is when they realized that the
        world seems so much more beautiful when they are together.
      </p>
    </Page>
  );
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const HolyMatrimonyPage = () => {
  const { inviteeInfoWorker } = React.useContext(InviteeInfoContext);
  const invitee = inviteeInfoWorker?.getInvitee();
  const [showGiftsLink, setShowGiftsLink] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    if (invitee) {
      setShowGiftsLink(true);
    }
  }, [!!invitee]);
  return (
    <Page
      className={classnames(PageStyles.solid, PageStyles.padded)}
      contentClassName={classnames(styles.holyMatrimonySection)}
    >
      <Subsection className={classnames(styles.scene)}>
        <h3 className={classnames(styles.heading, Typography.fontOpensauce)}>
          Holy Matrimony
        </h3>
        <div className={classnames(styles.when, Typography.fontPrata)}>
          <p>16 July 2022, Saturday</p>
          <p>10 AM onwards</p>
        </div>
        <div className={classnames(styles.where, Typography.fontPrata)}>
          <p>Immaculate Heart of Saint Virgin Mary Church</p>
          <p>Kumetiran No.13, Yogyakarta</p>
        </div>
      </Subsection>
      <Subsection>
        <h3
          className={classnames(styles.boldedSubheading, Typography.fontPrata)}
        >
          Livestream
        </h3>
        <div className={styles.liveStreamContainer}>
          <iframe
            src="https://www.youtube-nocookie.com/embed/KFuCjVcUDps"
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Subsection>
      {showGiftsLink && (
        <Subsection>
          {/* TODO: only show on targeted people */}
          <h3
            className={classnames(
              styles.boldedSubheading,
              Typography.fontPrata
            )}
          >
            Gifts
          </h3>
          <p>
            Your presence is more than enough! But if you want to help us
            prepare for our new life together, we've set up an online cash
            registry. Thanks in advance!
          </p>
          <LinkButtonContainer className={styles.subLinkButtonContainer}>
            <a
              className={OtherStyles.linkButton}
              href={"/gift/" + location.search}
              target="_blank"
            >
              Registry
            </a>
          </LinkButtonContainer>
        </Subsection>
      )}
    </Page>
  );
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const ReceptionPage = () => {
  const { inviteeInfoWorker } = React.useContext(InviteeInfoContext);
  return (
    <Page
      className={classnames(PageStyles.solid, PageStyles.padded)}
      contentClassName={classnames(styles.weddingReceptionSection)}
    >
      {/* TODO: only show on targeted people */}
      {/* TODO: RSVP? */}
      <Subsection className={classnames(styles.scene, Typography.fontPrata)}>
        <h3 className={classnames(styles.heading, Typography.fontOpensauce)}>
          Wedding Reception
        </h3>
        <div className={styles.when}>
          <p>16 July 2022, Saturday</p>
          <p>4.30 PM onwards</p>
        </div>
        <div className={styles.where}>
          <p>Sheraton Mustika Resort</p>
        </div>
      </Subsection>

      {pipe(
        inviteeInfoWorker?.getInvitee(),
        (invitee) =>
          invitee && (
            <Subsection className={styles.invitationSubsection}>
              <InvitationSubsection invitee={invitee} />
            </Subsection>
          )
      )}

      <Subsection>
        <h3
          className={classnames(styles.boldedSubheading, Typography.fontPrata)}
        >
          Attire
        </h3>
        <p>It's a garden wedding, so please make yourself comfortable!</p>
        <p>
          Gentlemen: Short-sleeved collared shirt, casual pants, comfy shoes
        </p>
        <p>
          Ladies: Comfortable dress, flat shoes (avoid pointy heels to save
          yourself from awkward moments!)
        </p>
        <LinkButtonContainer className={styles.subLinkButtonContainer}>
          <a
            className={OtherStyles.linkButton}
            href="https://id.pinterest.com/monicandyani/garden-wedding-outfit-recommendations/"
            target="_blank"
          >
            Pinterest Board
          </a>
        </LinkButtonContainer>
      </Subsection>
      <Subsection>
        <h3
          className={classnames(styles.boldedSubheading, Typography.fontPrata)}
        >
          Accomodations
        </h3>
        <p>
          There are several boutique hotels near the venue. Click on the link to
          see the full list.
        </p>
        <p>
          We have a special price if you want to stay at Sheraton! Let us know
          and we'll be in touch with you soon!
        </p>
        <LinkButtonContainer className={styles.subLinkButtonContainer}>
          <a
            className={OtherStyles.linkButton}
            href="https://www.google.com/maps/search/hotels/@-7.7818017,110.4265908,15z/data=!3m1!4b1"
            target="_blank"
          >
            Book Now
          </a>
        </LinkButtonContainer>
      </Subsection>
    </Page>
  );
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const InvitationSubsection = ({ invitee }: { invitee: InviteeData }) => {
  const [responseFieldOpen, setResponseFieldOpen] = React.useState(false);

  const inviteeDetailFilled =
    (invitee.attendance && invitee.phoneNumber) || invitee.attendance === -1;
  const willAttend = (invitee.attendance && invitee.attendance > 0) || false;
  return (
    <>
      <h3 className={classnames(styles.boldedSubheading, Typography.fontPrata)}>
        Dear <em>{invitee.name}</em>, <br />
        You are invited
      </h3>
      {!inviteeDetailFilled && (
        <p>We kindly request your reply before July 7th</p>
      )}
      {!responseFieldOpen && inviteeDetailFilled && willAttend && (
        <div className={styles.existingResponse}>
          <p>You have given your response</p>
          <div>
            Your contact detail is <strong>{invitee.phoneNumber}</strong>
          </div>
          <div>
            Persons attending: <strong>{invitee.attendance}</strong>
          </div>
        </div>
      )}
      {!responseFieldOpen && inviteeDetailFilled && !willAttend && (
        <div className={styles.existingResponse}>
          <p>You have given your response</p>
          <div>You are not attending the wedding reception</div>
        </div>
      )}
      {!responseFieldOpen && (
        <LinkButtonContainer>
          <button
            onClick={() => setResponseFieldOpen(true)}
            className={OtherStyles.linkButton}
            type="button"
          >
            {!inviteeDetailFilled && "Respond"}
            {inviteeDetailFilled && "Change response"}
          </button>
        </LinkButtonContainer>
      )}
      {responseFieldOpen && (
        <InvitationForm
          oldInviteeData={invitee}
          onClose={() => setResponseFieldOpen(false)}
        />
      )}
    </>
  );
};

export const ReceptionAreaLoading = () => (
  <Page
    className={classnames(PageStyles.solid, PageStyles.padded)}
    contentClassName={classnames(styles.weddingReceptionSection)}
  >
    <Subsection className={styles.invitationSubsection}>
      <div className={styles.invitationLoading}>
        <Spinner />
        Loading your invitation
      </div>
    </Subsection>
  </Page>
);

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export namespace InvitationFormHelper {
  export const MAX_ATTENDANCE = 3;
  export const MIN_ATTENDANCE = 1;
  export const sanitizePhoneNumber = (str: string) =>
    str
      .trim()
      .split("")
      .filter((char, index) => {
        if (!Number.isNaN(Number(char))) {
          return true;
        }
        if (char === "+" && index === 0) {
          return true;
        }
        return false;
      })
      .join("");
  export const pinAttendance = (num: number) =>
    Math.min(
      Math.max(num, InvitationFormHelper.MIN_ATTENDANCE),
      InvitationFormHelper.MAX_ATTENDANCE
    );
}
export const InvitationForm = ({
  oldInviteeData,
  onClose,
}: {
  oldInviteeData: InviteeData;
  onClose?: () => unknown;
}) => {
  const { inviteeInfoWorker, row } = React.useContext(InviteeInfoContext);
  const [phoneNumber, setPhoneNumber] = React.useState(
    InvitationFormHelper.sanitizePhoneNumber(oldInviteeData.phoneNumber || "")
  );
  const [willAttend, setWillAttend] = React.useState<null | boolean>(
    oldInviteeData.attendance === null ? null : oldInviteeData.attendance > 0
  );
  const [attendance, setAttendance] = React.useState(
    String(
      InvitationFormHelper.pinAttendance(
        oldInviteeData.attendance || InvitationFormHelper.MIN_ATTENDANCE
      )
    )
  );

  const submit = async () => {
    if (!inviteeInfoWorker) return;
    if (!row) return;
    setIsLoading(true);
    console.log(
      willAttend === false
        ? { attendance: -1, phoneNumber: "" }
        : { attendance, phoneNumber }
    );
    const result = await inviteeInfoWorker.update(
      row,
      willAttend === false
        ? { attendance: -1, phoneNumber: "" }
        : {
            attendance: InvitationFormHelper.pinAttendance(
              Number(attendance) || InvitationFormHelper.MIN_ATTENDANCE
            ),
            phoneNumber,
          }
    );
    setIsLoading(false);

    if (E.isRight(result)) {
      setIsSaveSuccessful(true);
      setTimeout(() => {
        onClose?.();
      }, 3000);
    }
  };

  const [isSaveSuccessful, setIsSaveSuccessful] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const isSubmittable = willAttend === false || (phoneNumber && attendance);

  return (
    <FormFrame>
      <FormFrameHead
        onClickX={() => {
          if (isSaveSuccessful || isLoading) return;
          onClose?.();
        }}
      />
      <div className={styles.formInputs}>
        <FormGroup
          className={classnames(
            styles.fadeable,
            !isLoading && !isSaveSuccessful && styles.visible
          )}
        >
          <label>Will you attend the wedding reception?</label>

          <div className={styles.checkboxContainer}>
            <Checkbox
              label="Yes"
              disabled={isLoading}
              checked={willAttend === true}
              onClick={() => setWillAttend(true)}
            />

            <Checkbox
              label="No"
              disabled={isLoading}
              checked={willAttend === false}
              onClick={() => setWillAttend(false)}
            />
          </div>
        </FormGroup>
        {willAttend && (
          <>
            <FormGroup
              className={classnames(
                styles.fadeable,
                !isLoading && !isSaveSuccessful && styles.visible
              )}
            >
              <label>How many will attend?</label>
              <input
                type="number"
                value={attendance}
                disabled={isLoading}
                onChange={(e) => {
                  e.preventDefault();
                  setAttendance(e.target.value);
                }}
                onBlur={(e) => {
                  setAttendance((x) =>
                    String(
                      InvitationFormHelper.pinAttendance(
                        Number(x) || InvitationFormHelper.MIN_ATTENDANCE
                      )
                    )
                  );
                }}
                min={InvitationFormHelper.MIN_ATTENDANCE}
                max={InvitationFormHelper.MAX_ATTENDANCE}
              ></input>
            </FormGroup>
            <FormGroup
              className={classnames(
                styles.fadeable,
                !isLoading && !isSaveSuccessful && styles.visible
              )}
            >
              <label>Phone number</label>
              <input
                type="text"
                placeholder="Example: +628123456789"
                value={phoneNumber}
                disabled={isLoading}
                onChange={(e) => {
                  setPhoneNumber(
                    InvitationFormHelper.sanitizePhoneNumber(
                      String(e.target.value)
                    )
                  );
                }}
              ></input>
            </FormGroup>
          </>
        )}
        <FormGroup
          className={classnames(
            styles.fadeable,
            !isLoading && !isSaveSuccessful && styles.visible
          )}
        >
          <button
            disabled={!isSubmittable}
            onClick={() => submit()}
            type="button"
            className={OtherStyles.linkButton}
          >
            Submit
          </button>
        </FormGroup>
        <div
          className={classnames(
            styles.responseSaving,
            styles.fadeable,
            isLoading && styles.visible
          )}
        >
          <Spinner />
          Saving your response
        </div>
        <div
          className={classnames(
            styles.responseSaveSuccess,
            styles.fadeable,
            !isLoading && isSaveSuccessful && styles.visible
          )}
        >
          Response saved successfully
        </div>
      </div>
    </FormFrame>
  );
};

export const GiftLinkPage = () => {
  const location = useLocation();
  return (
    <Page
      className={classnames(PageStyles.solid, PageStyles.padded)}
      contentClassName={classnames(styles.weddingReceptionSection)}
    >
      <Subsection>
        {/* TODO: only show on targeted people */}
        <h3
          className={classnames(styles.boldedSubheading, Typography.fontPrata)}
        >
          Gifts
        </h3>
        <p>
          Your presence is more than enough! But if you want to help us prepare
          for our new life together, we've set up an online cash registry.
          Thanks in advance!
        </p>
        <LinkButtonContainer className={styles.subLinkButtonContainer}>
          <a
            className={OtherStyles.linkButton}
            href={"/gift/" + location.search}
            target="_blank"
          >
            Registry
          </a>
        </LinkButtonContainer>
      </Subsection>
    </Page>
  );
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

const CountdownItem = ({
  unit,
  amount,
}: {
  unit: React.ReactNode;
  amount: React.ReactNode;
}) => (
  <div className={styles.item}>
    <div className={styles.amount}>{amount}</div>
    <div className={styles.unit}>{unit}</div>
  </div>
);

const Countdown = () => {
  const [{ seconds, minutes, hours, days, months }, setRemainingTime] =
    React.useState<Duration>(getRemainingDuration());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getRemainingDuration());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div className={styles.countdown}>
      <CountdownItem amount={months} unit="Months" />
      <CountdownItem amount={days} unit="Days" />
      <CountdownItem amount={hours} unit="Hours" />
      <CountdownItem amount={minutes} unit="Minutes" />
      <CountdownItem amount={seconds} unit="Seconds" />
    </div>
  );
};

const CountdownWidget = () => (
  <div className={styles.countdownSection}>
    <div className={styles.commencingIn}>Commencing in</div>
    <Countdown />
  </div>
);

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export default IndexPage;
