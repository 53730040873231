import * as TE from "fp-ts/lib/TaskEither";
import * as E from "fp-ts/lib/Either";
import * as t from "io-ts";
import * as UniversalBase64 from "universal-base64";

import {
  CommandDscCodec,
  PatchCommandCodec,
  RetrieveCommandCodec,
} from "./data";
import { pipe } from "fp-ts/lib/function";
import { Cipher, ChipCodec } from "./cipher";

export const SPREADSHEET_ID = UniversalBase64.decode(
  "MTFFRjAxajJFUG9oTDNqb2dwVWZTYmJuaE95V3dJV1NBeGp6aXNTY2JpY2M="
);
export const SHEET_NAME = UniversalBase64.decode("cnN2cA==");
export const IssuingURL = new URL("https://almonwedding.herokuapp.com/issue");
// export const IssuingURL = new URL("http://localhost:3001/issue");

export const lazy = <T extends any>(fn: () => T): (() => T) => {
  let cached: null | T = null;
  return (): T => {
    if (cached) return cached;
    else {
      cached = fn();
      return cached;
    }
  };
};

export const FetchGuards = {
  network: (
    promise: ReturnType<typeof fetch>
  ): Promise<E.Either<string, Response>> =>
    promise.then((res) => E.right(res)).catch((err) => E.left("Network Error")),

  extract: async (response: Response): Promise<E.Either<string, unknown>> =>
    await response
      .json()
      .then((res) => E.right(res))
      .catch(() => E.left("NotJSON Error")),
};

export type Command = t.TypeOf<typeof CommandDscCodec>;
export type RetrieveCommand = t.TypeOf<typeof RetrieveCommandCodec>;
export type PatchCommand = t.TypeOf<typeof PatchCommandCodec>;

export const encodeCommandForTransport = (cipher: Cipher, command: Command) =>
  pipe(command, JSON.stringify, cipher.encode, E.map(JSON.stringify));
