import * as t from "io-ts";
import * as E from "fp-ts/lib/Either";
import * as UniversalBase64 from "universal-base64";
import * as Caesar from "@easy-cipher/caesar";
import { pipe } from "fp-ts/lib/function";

// Tuple of [Version, Body]
export const ChipCodec = t.tuple([t.literal("1"), t.string]);
export type Chip = t.TypeOf<typeof ChipCodec>;

export type Key = {
  rot: number;
};

export const Base64 = {
  encode: (str: string) => Buffer.from(str).toString("base64"),
  decode: (str64: string) => Buffer.from(str64, "base64").toString("utf8"),
};

export const Reverse = {
  reverse: (str: string) => str.split("").reverse().join(""),
};

export const Charr = {
  encode: (str: string) =>
    str
      .split("")
      .map((item) => String(item.charCodeAt(0)))
      .join("."),

  decode: (str: string) =>
    str
      .split(".")
      .map((item) => {
        const no = Number(item);
        if (Number.isNaN(no)) throw new Error("invalid charcode");
        return String.fromCharCode(no);
      })
      .join(""),
};

export type Cipher = ReturnType<typeof Cipher["make"]>;
export const Cipher = {
  make: (key: Key) => {
    const caesar = Caesar.caesar({ encryptionOffset: key.rot });

    const cipher = {
      s2sEncode: (str: string): E.Either<string, string> =>
        E.tryCatch(
          () =>
            pipe(
              str,
              // base6
              UniversalBase64.encode,
              // reverse
              Reverse.reverse,
              // charr
              Charr.encode
            ),
          (e) => String((e instanceof Error && e.message) || e)
        ),

      s2sDecode: (str: string): E.Either<string, string> =>
        E.tryCatch(
          () =>
            pipe(
              str,
              // charr
              Charr.decode,
              // reverse
              Reverse.reverse,
              // base64
              UniversalBase64.decode
            ),
          (e) => String((e instanceof Error && e.message) || e)
        ),

      decode: (chip: Chip): E.Either<string, string> =>
        cipher.s2sDecode(chip[1]),

      encode: (str: string): E.Either<string, Chip> =>
        pipe(
          cipher.s2sEncode(str),
          E.chainW((str) => E.right(["1", str]))
        ),
    };

    return cipher;
  },
};
